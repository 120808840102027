import { ChevronDownIcon, ChevronUpIcon } from "@heroicons/react/outline"; // Make sure you install Heroicons for the icons
import React, { useState } from "react";

export default function Collapse({ children, title, isOpenDefault = false }) {
  const [isOpen, setOpen] = useState(isOpenDefault);

  return (
    <div className="border-b-2 border-gray-300">
      <div
        className="flex justify-between items-center cursor-pointer"
        onClick={() => setOpen(!isOpen)}
      >
        <h2 className="text-lg mb-2 font-semibold">{title}</h2>
        {isOpen ? (
          <ChevronUpIcon className="h-5 w-5" />
        ) : (
          <ChevronDownIcon className="h-5 w-5" />
        )}
      </div>
      {isOpen && <div className="flex flex-wrap gap-2 mt-4 mb-4">{children}</div>}
    </div>
  );
}
