// src/components/SearchAgain.js

import React from 'react';

const SearchAgain = ({ ideaText, domains }) => {

  const handleSearchAgain = () => {
    // Extract the list of domain names
    const excludedDomains = domains.map((domain) => domain.domain).join(', ');

    // Extract the original ideaText without any previous 'Do not use these domains' sections
    const ideaTextWithoutExclusions = ideaText.split('\n\nDo not use these domains:')[0];

    // Append the excluded domains to the original ideaText
    const newIdeaText = `${ideaTextWithoutExclusions}\n\nDo not use these domains: ${excludedDomains}`;

    // Store newIdeaText in localStorage
    localStorage.setItem('newIdeaText', newIdeaText);

    // Refresh the page
    window.location.reload();
  };

  return (
    <button
      onClick={handleSearchAgain}
      className="mt-4 mb-4 px-4 py-2 bg-blue-600 text-white rounded-lg hover:bg-blue-700 transition"
    >
      Copy domains and start again
    </button>
  );
};

export default SearchAgain;