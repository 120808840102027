import { Dialog, Transition } from "@headlessui/react"; // For modal handling
import { ClipboardIcon } from "@heroicons/react/outline";
import { CurrencyDollarIcon } from "@heroicons/react/solid";
import React, { useState } from "react";
import { useParams } from "react-router-dom";

const Affiliate = () => {
  const { domainName: encodedDomainName } = useParams(); // Get encoded domainName from URL params
  const domainName = decodeURIComponent(
    encodedDomainName || "Domain not found"
  ); // Decode the domainName
  const [isCopied, setIsCopied] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);

  const handleCopyClick = () => {
    navigator.clipboard.writeText(domainName);
    setIsCopied(true);
  };

  const handleProviderClick = (e) => {
    if (!isCopied) {
      e.preventDefault(); // Prevent navigating to the link
      setIsModalOpen(true); // Open the modal if domain is not copied
    }
  };

  const closeModal = () => {
    setIsModalOpen(false);
  };

  const copyInModal = () => {
    navigator.clipboard.writeText(domainName);
    setIsCopied(true);
    closeModal();
  };

  const logos = [
    {
      src: "https://img.logo.dev/namesilo.com?token=pk_W7rOalIITKWrxu3SgkGDDQ",
      alt: "Namesilo",
      link: `https://www.namesilo.com/domain/search-domains?rid=4e7ed58ss&query=${encodedDomainName}`,
    },
    {
      src: "https://img.logo.dev/bluehost.com?token=pk_W7rOalIITKWrxu3SgkGDDQ",
      alt: "BlueHost",
      link: "https://bluehost.sjv.io/4PZ0YG",
    },
    {
      src: "https://img.logo.dev/hostgator.com?token=pk_W7rOalIITKWrxu3SgkGDDQ",
      alt: "Hostgator",
      link: "https://partners.hostgator.com/m5DdxZ",
    },
    {
      src: "https://img.logo.dev/gcd.com?token=pk_W7rOalIITKWrxu3SgkGDDQ",
      alt: "GoDaddy",
      link: "https://www.godaddy.com/en-ca/offers/domain-airo?isc=cjcfos3",
    },
    {
      src: "https://img.logo.dev/df.eu?token=pk_W7rOalIITKWrxu3SgkGDDQ",
      alt: "Domainfactory",
      link: "https://www.df.eu/de/domains/",
    },
    {
      src: "https://img.logo.dev/ultahost.com?token=pk_W7rOalIITKWrxu3SgkGDDQ",
      alt: "Ultahost",
      link: `https://ultahost.com/domains?domain=${encodedDomainName}`,
    },
  ];

  const secretLinks = [
    {
      name: "AWS",
      description:
        "AWS Activate is a comprehensive program designed to support startups and businesses and provide them with the resources and tools they need to build and scale their businesses on the Amazon Web Services (AWS) cloud platform. With AWS Activate, startups can accelerate their growth, innovate with confidence, and access a wide range of benefits and resources.",
      logo: "https://img.logo.dev/aws.com?token=pk_W7rOalIITKWrxu3SgkGDDQ", // Update this to the correct logo path
      buttonText: "30-60% off of monthly spend\n\n and $2,000 in credits",
      link: "https://www.joinsecret.com/aws-activate#aws-credits-startups?via=benjamin", // Add the correct link here
    },
    {
      name: "OpenAI - ChatGPT",
      description:
        "OpenAI’s platform offers a suite of advanced AI tools designed to empower businesses, researchers, and developers, including ChatGPT, a state-of-the-art conversational model that enhances interactions and facilitates intelligent communication.",
      logo: "https://img.logo.dev/openai.com?token=pk_W7rOalIITKWrxu3SgkGDDQ", // Update this to the correct logo path
      buttonText: "$2,500 in API credits \n\n for 6 months",
      link: "https://www.joinsecret.com/openai#openai-promocode?via=benjamin", // Add the correct link here
    },
    {
      name: "Stripe",
      description:
        "Stripe is a payment solution for ecommerce websites. It can be easily integrated to your website or app, and allows you to accept your customers payments.",
      logo: "https://img.logo.dev/stripe.com?token=pk_W7rOalIITKWrxu3SgkGDDQ", // Update this to the correct logo path
      buttonText: "Waived Stripe fees on  \n\n$20,000 in payments",
      link: "https://www.joinsecret.com/stripe#stripe-coupon?via=benjamin", // Add the correct link here
    },
    {
      name: "Make",
      description:
        "Make (make.com) is an automation platform leveraging AI to organize your company’s workflows. Ideal for self-employed entrepreneurs, large companies, and startups, this SaaS solution optimizes all key processes.",
      logo: "https://img.logo.dev/make.com?token=pk_W7rOalIITKWrxu3SgkGDDQ", // Update this to the correct logo path
      buttonText: "12 months free\n\n on the Teams plan",
      link: "https://www.joinsecret.com/make#make-coupon?via=benjamin", // Add the correct link here
    },
    {
      name: "Google Cloud",
      description:
        "The Google Cloud platform offers comprehensive and diversified cloud-based services. It brings together storage, security, networking, big data, machine learning and application development solutions all in one place.",
      logo: "https://img.logo.dev/googlecloudpresscorner.com?token=pk_W7rOalIITKWrxu3SgkGDDQ", // Update this to the correct logo path
      buttonText: "$2,000 in credits\n\n for 1 year for startups",
      link: "https://www.joinsecret.com/google-cloud#google-cloud-promocode-200000?via=benjamin", // Add the correct link here
    },
    {
      name: "Microsoft Azure",
      description:
        "Microsofts Azure is a powerful cloud platform designed for all types of organizations. More than just a place to store data or run apps, it is built to help businesses grow and innovate. A special feature of Azure is the Microsoft for Startups program.",
      logo: "https://img.logo.dev/azure.com?token=pk_W7rOalIITKWrxu3SgkGDDQ", // Update this to the correct logo path
      buttonText: "$25,000 in Cloud credits\n\n for 1 year",
      link: "https://www.joinsecret.com/microsoft-for-startups#microsoft-for-startups-coupon-150?via=benjamin", // Add the correct link here
    },
    {
      name: "Webflow",
      description:
        "f you’re looking to design an attractive, high-performance website quickly and without the need for developers, you can use the Webflow tool. \n\nThis CMS (Content Management System) is a platform that offers you the opportunity to create a website for your startup without the need for technical coding knowledge. \n\nWebflow employs AI-driven design suggestions and automated code generation, seamlessly transforming your drag-and-drop visual designs into clean, production-ready code for rapid and efficient website deployment.",
      logo: "https://img.logo.dev/webflow.com?token=pk_W7rOalIITKWrxu3SgkGDDQ", // Update this to the correct logo path
      buttonText: "1 year free on a \n\nCMS site plan",
      link: "https://www.joinsecret.com/webflow#webflow-coupon?via=benjamin", // Add the correct link here
    },
    {
      name: "Airtable",
      description:
        "Airtable is a no-code database solution that is an essential tool for your startup. Combining spreadsheets and a database, this SaaS-based platform is the perfect software for facilitating collaborative working. All members of your team can store, share, and modify information. Airtable uses AI to automatically categorize and tag your data, suggest relationships between records, and even generate summaries and insights from large datasets, all without the need for coding or complex formulas.",
      logo: "https://img.logo.dev/airtable.com?token=pk_W7rOalIITKWrxu3SgkGDDQ", // Update this to the correct logo path
      buttonText: "$1,000 in credits \n\nfor 1 year",
      link: "https://www.joinsecret.com/airtable#airtable-credits-startups", // Add the correct link here
    },
  ];

  return (
    <div className="min-h-screen bg-gray-100 py-12 px-4 sm:px-6 lg:px-8">
      <div className="max-w-7xl mx-auto">
        {/* Support Section */}
        <div className="bg-blue-600 rounded-lg shadow-xl overflow-hidden mb-12">
          <div className="p-6 sm:p-10 text-center">
            <h2 className="text-3xl font-bold text-white mb-4">
              Support the Project
            </h2>
            <p className="text-xl text-blue-100 mb-6">
              Help us continue providing great services and get exclusive
              benefits!
            </p>
            <a
              href="https://donate.stripe.com/00g5kBbQi610cpycMY"
              target="_blank"
              rel="noopener noreferrer"
              className="inline-flex items-center px-8 py-4 border border-transparent text-lg font-medium rounded-md text-blue-600 bg-white hover:bg-blue-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500 transition duration-150 ease-in-out"
            >
              <CurrencyDollarIcon className="h-6 w-6 mr-2" />
              Support Now
            </a>
          </div>
        </div>

        {/* Domain Search Result Section */}
        <div className="bg-white rounded-lg shadow-xl overflow-hidden">
          <div className="p-6 sm:p-10">
            <h1 className="text-3xl font-bold text-gray-900 mb-4">
              Domain Search Result
            </h1>
            <div className="flex items-center space-x-4 mb-6">
              <h2 className="text-2xl font-semibold text-gray-700">
                {domainName}
              </h2>
              <button
                className="p-2 bg-gray-100 rounded-full hover:bg-gray-200 transition-colors"
                onClick={handleCopyClick}
              >
                <ClipboardIcon className="w-5 h-5 text-gray-600" />
              </button>
              {isCopied && (
                <span className="text-green-600 text-sm ml-2">Copied</span>
              )}
            </div>
            <h3 className="text-xl font-semibold text-gray-700 mb-4">
              Please select your preferred seller
            </h3>
            <div className="grid grid-cols-2 sm:grid-cols-3 md:grid-cols-6 gap-4 mb-8">
              {logos.map((logo, index) => (
                <a
                  key={index}
                  href={logo.link}
                  target="_blank"
                  rel="noopener noreferrer"
                  className="flex flex-col items-center p-4 border rounded-lg hover:shadow-md transition-shadow"
                  onClick={handleProviderClick}
                >
                  <img
                    src={logo.src}
                    alt={logo.alt}
                    className="mb-2 max-h-24"
                  />
                  <span className="text-sm font-medium text-gray-700">
                    {logo.alt}
                  </span>
                </a>
              ))}
            </div>
          </div>
        </div>

        {/* Secret Join Section */}
        <div className="py-12 px-4 sm:px-6 lg:px-8 bg-gray-100">
          <div className="max-w-7xl mx-auto">
            <h2 className="text-3xl font-bold text-gray-900 mb-6">
              We have partnered with
              <a href="https://www.joinsecret.com?via=benjamin">
                {" "}
                JOINSECRET{" "}
              </a>{" "}
              so you can save thousands!
            </h2>

            <div className="space-y-6">
              {secretLinks.map((service, index) => (
                <div
                  key={index}
                  className="bg-white shadow-md rounded-lg overflow-hidden"
                >
                  {/* First Row: Logo, Title, and Button */}
                  <div className="p-6 sm:p-8 flex flex-col sm:flex-row sm:items-center sm:justify-between space-y-4 sm:space-y-0">
                    <div className="flex flex-col sm:flex-row sm:items-center sm:space-x-4">
                      {/* Logo */}
                      <img
                        src={service.logo}
                        alt={`${service.name} logo`}
                        width={48}
                        height={48}
                        className="flex-shrink-0"
                      />
                      {/* Title */}
                      <h3 className="text-xl font-semibold text-gray-900 mt-2 sm:mt-0">
                        {service.name}
                      </h3>
                    </div>

                    {/* Button Section */}
                    <div className="flex-shrink-0 flex flex-col items-end">
                      <a
                        href={service.link}
                        target="_blank"
                        rel="noopener noreferrer"
                        className="w-full sm:w-auto px-6 py-3 bg-green-500 hover:bg-green-600 text-white font-semibold rounded-md transition-colors text-center"
                        style={{
                          minWidth: "180px",
                          whiteSpace: "pre-wrap",
                          lineHeight: ".7",
                        }}
                      >
                        {service.buttonText}
                      </a>
                    </div>
                  </div>

                  {/* Second Row: Description */}
                  <div className="px-6 sm:px-8 pb-6">
                    <p className="text-gray-600 pr-4 sm:pr-8 break-words">
                      {service.description}
                    </p>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>

      {/* Modal for copying domain */}
      <Transition show={isModalOpen} as={React.Fragment}>
        <Dialog as="div" className="relative z-10" onClose={closeModal}>
          <div className="fixed inset-0 bg-black bg-opacity-30" />
          <div className="fixed inset-0 z-10 overflow-y-auto">
            <div className="flex min-h-full items-center justify-center p-4 text-center">
              <Dialog.Panel className="w-full max-w-md transform overflow-hidden rounded-lg bg-white p-6 text-left shadow-xl transition-all">
                <Dialog.Title className="text-lg font-medium leading-6 text-gray-900">
                  Don't forget to copy your domain name
                </Dialog.Title>
                <div className="mt-2">
                  <p className="text-sm text-gray-500">
                    Click the button below to copy the domain name to your
                    clipboard before selecting a provider.
                  </p>
                </div>
                <div className="mt-4">
                  <button
                    type="button"
                    className="inline-flex justify-center rounded-md bg-indigo-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-500"
                    onClick={copyInModal}
                  >
                    Copy to Clipboard
                  </button>
                </div>
              </Dialog.Panel>
            </div>
          </div>
        </Dialog>
      </Transition>
    </div>
  );
};

export default Affiliate;
