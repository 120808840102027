import React from "react";

export default function ToggleButton({
  title,
  isDisabled = false,
  isSelected = false,
  onClick,
}) {
  return (
    <button
      type="button"
      onClick={() => onClick()}
      className={`px-3 py-1 rounded-full border ${
        isSelected ? "bg-blue-600 text-white" : "bg-gray-200"
      } hover:bg-blue-500 hover:text-white transition`}
      disabled={isDisabled}
    >
      {title}
    </button>
  );
}
