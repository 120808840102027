// src/pages/Home.js

import {
  CheckCircleIcon,
  CurrencyDollarIcon,
  XIcon,
} from "@heroicons/react/solid";
import React, { useEffect, useState } from "react";
import Logo from "../common/components/Logo";
import AppPageLayout from "../common/layouts/AppPageLayout";
import DomainSearchForm from "../components/DomainSearchForm";
import ResultsTable from "../components/ResultsTable";

const SearchState = {
  INIT: () => ["INIT", null],
  LOADING: () => ["LOADING", null],
  SUCCESS: (data) => ["SUCCESS", data],
  FAILURE: (error) => ["FAILURE", error],
};

function Home() {
  const [searchState, setSearchState] = useState(SearchState.INIT());
  const [showSuccessAlert, setShowSuccessAlert] = useState(false);
  const [loadingText, setLoadingText] = useState("Brainstorming");
  const [ideaText, setIdeaText] = useState("");

  // On component mount, check for newIdeaText in localStorage
  useEffect(() => {
    const storedIdeaText = localStorage.getItem("newIdeaText");
    if (storedIdeaText) {
      setIdeaText(storedIdeaText);
      // Clear the localStorage item so it doesn't persist across other uses
      localStorage.removeItem("newIdeaText");
    }
  }, []);

  // Update the loading text as per the required intervals
  useEffect(() => {
    if (searchState[0] === "LOADING") {
      const textSequence = [
        "Brainstorming",
        "Creating a list",
        "Checking availability",
      ];
      let currentIndex = 0;

      const interval = setInterval(() => {
        currentIndex += 1;
        if (currentIndex < textSequence.length) {
          setLoadingText(textSequence[currentIndex]);
        }
      }, 8000);

      return () => clearInterval(interval);
    }
  }, [searchState]);

  const handleSubmit = async (data) => {
    setSearchState(SearchState.LOADING());
    console.log("Submitting search", data);
    await new Promise((resolve) => setTimeout(resolve, 2000));

    try {
      const response = await fetch("https://search-wylgq563yq-uc.a.run.app", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(data),
      });

      if (!response.ok) {
        throw new Error("Network response was not ok");
      }

      const responseData = await response.json();
      setSearchState(SearchState.SUCCESS(responseData));
      setShowSuccessAlert(true);
    } catch (error) {
      console.error("Error submitting search:", error);
      setSearchState(SearchState.FAILURE(error.message));
    }
  };

  return (
    <AppPageLayout>
      <div>
        <Logo />
        <h1 className="text-2xl mt-4 font-bold text-gray-800">
          Names and domains, instantly.
        </h1>
        <a
          href="https://donate.stripe.com/00g5kBbQi610cpycMY"
          target="_blank"
          rel="noopener noreferrer"
          className="inline-flex items-center px-3 py-1 mt-4 border border-blue-500 rounded-full text-blue-500 hover:bg-blue-500 hover:text-white transition-colors duration-200"
        >
          <CurrencyDollarIcon className="h-4 w-4 mr-1" />
          <span>Be amazing and donate to help pay the costs</span>
        </a>
        <p className="text-gray-600 mt-4 leading-relaxed">
          Stop struggling with unavailable domains. <br />
          Use Domain Ideas to generate names and check availability instantly.{" "}
          <br />
        </p>
      </div>

      {searchState[0] === "INIT" ? (
        <DomainSearchForm
          onSubmit={handleSubmit}
          isDisabled={searchState[0] === "LOADING"}
          initialIdeaText={ideaText} // Pass the prefilled text to DomainSearchForm
        />
      ) : (
        <div>
          {searchState[0] === "LOADING" && (
            <div className="flex justify-center items-center mt-16 mb-16">
              <p className="text-4xl font-bold text-gray-800">
                {loadingText}
                <span className="dot-1">.</span>
                <span className="dot-2">.</span>
                <span className="dot-3">.</span>
              </p>
            </div>
          )}

          {searchState[0] === "SUCCESS" && (
            <div>
              {showSuccessAlert && (
                <div className="rounded-md bg-green-50 p-4 mb-4 mt-6">
                  <div className="flex">
                    <div className="flex-shrink-0">
                      <CheckCircleIcon
                        aria-hidden="true"
                        className="h-5 w-5 text-green-400"
                      />
                    </div>
                    <div className="ml-3">
                      <p className="text-sm font-medium text-green-800">
                        Search completed successfully!
                      </p>
                    </div>
                    <div className="ml-auto pl-3 ">
                      <div className="-mx-1.5 -my-1.5">
                        <button
                          type="button"
                          className="inline-flex rounded-md bg-green-50 p-1.5 text-green-500 hover:bg-green-100 focus:outline-none focus:ring-2 focus:ring-green-600 focus:ring-offset-2 focus:ring-offset-green-50"
                          onClick={() => setShowSuccessAlert(false)}
                        >
                          <span className="sr-only">Dismiss</span>
                          <XIcon aria-hidden="true" className="h-5 w-5" />
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              )}

              {searchState[1].domains && searchState[1].domains.length > 0 ? (
                <ResultsTable
                  results={searchState[1].domains}
                  ideaText={ideaText}
                />
              ) : (
                <p>No results found.</p>
              )}
            </div>
          )}

          {searchState[0] === "FAILURE" && (
            <p>Error occurred: {searchState[1]}</p>
          )}
        </div>
      )}
    </AppPageLayout>
  );
}

export default Home;
