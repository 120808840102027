// src/components/DomainSearchForm.js
import React, { useEffect, useState } from "react";
import { internationalTlds, popularTlds } from "../utils/TLDS";
import Collapse from "./Collapse";
import ToggleButton from "./ToggleButton";

const MAX_TLDS = 5;

const DomainSearchForm = ({ onSubmit, isDisabled, initialIdeaText = "" }) => {
  // Add initialIdeaText here
  const [selectedTlds, setSelectedTlds] = useState([".com"]);
  const [ideaText, setIdeaText] = useState(initialIdeaText); // Set initial state from initialIdeaText

  // Update ideaText if initialIdeaText changes
  useEffect(() => {
    if (initialIdeaText) {
      setIdeaText(initialIdeaText);
    }
  }, [initialIdeaText]);

  const handleTldSelect = (tld) => {
    if (selectedTlds.includes(tld)) {
      setSelectedTlds(selectedTlds.filter((selected) => selected !== tld));
    } else if (selectedTlds.length < MAX_TLDS) {
      setSelectedTlds([...selectedTlds, tld]);
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (selectedTlds.length > 0) {
      onSubmit({ tlds: selectedTlds, ideaText });
    } else {
      alert("Don't forget to select a domain extension");
    }
  };

  return (
    <form
      onSubmit={handleSubmit}
      className="max-w-[1000px] mt-8   bg-white space-y-8"
    >
      <div>
        <textarea
          placeholder="To get the best results, briefly describe your product or service, name ideas, and any style or theme preferences."
          className="w-full h-80 p-4 border rounded-lg border-gray-300 focus:outline-none focus:ring-2 focus:ring-blue-500 resize-none overflow-y-auto"
          required
          value={ideaText}
          onChange={(e) => setIdeaText(e.target.value)}
          style={{
            textAlign: "left",
            verticalAlign: "top",
            whiteSpace: "pre-wrap",
          }}
        />
      </div>
      <p>Select up to five domain extensions</p>
      <div className="space-y-4">
        <Collapse title="Common" isOpenDefault={true}>
          {popularTlds.map((tld) => (
            <ToggleButton
              key={tld}
              title={tld}
              isDisabled={
                isDisabled ||
                (selectedTlds.length >= MAX_TLDS && !selectedTlds.includes(tld))
              }
              isSelected={selectedTlds.includes(tld)}
              onClick={() => handleTldSelect(tld)}
            />
          ))}
        </Collapse>
        <Collapse title="International">
          {internationalTlds.map((tld) => (
            <ToggleButton
              key={tld}
              title={tld}
              isDisabled={
                isDisabled ||
                (selectedTlds.length >= MAX_TLDS && !selectedTlds.includes(tld))
              }
              isSelected={selectedTlds.includes(tld)}
              onClick={() => handleTldSelect(tld)}
            />
          ))}
        </Collapse>
      </div>

      <div>
        <button
          type="submit"
          disabled={isDisabled}
          className="w-full py-3 bg-blue-600 text-white font-semibold rounded-lg hover:bg-blue-700 transition"
        >
          {selectedTlds.length > 0 ? "Search Domains" : "Select an extension"}
        </button>
      </div>
    </form>
  );
};

export default DomainSearchForm;
