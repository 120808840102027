//src/components/ResultsTable.js
import React from "react";
import { useNavigate } from "react-router-dom";
import SearchAgain from "./SearchAgain"; // Import the SearchAgain component

const ResultsTable = ({ results, ideaText }) => {
  const navigate = useNavigate();

  // Sort by availability (available first) and then alphabetically by domain name
  const sortedDomains = results.sort((a, b) => {
    if (a.available !== b.available) {
      return b.available ? 1 : -1; // Available domains first
    }
    return a.domain.localeCompare(b.domain);
  });

  // Group domains alphabetically
  const groupedDomains = sortedDomains.reduce((acc, domain) => {
    const firstLetter = domain.domain[0].toUpperCase();
    if (!acc[firstLetter]) {
      acc[firstLetter] = [];
    }
    acc[firstLetter].push(domain);
    return acc;
  }, {});

  const handleBuyNowClick = (domain) => {
    navigate(`/affiliate/${encodeURIComponent(domain.domain)}`);
  };

  return (
    <div className="mt-4">
      {/* Add the SearchAgain button ABOVE the table */}
      <div className="flex justify-center mb-6">
        <SearchAgain ideaText={ideaText} domains={results} />
      </div>

      <div
        className="mt-6 h-96 overflow-y-auto ring-1 ring-gray-300 sm:rounded-lg"
        style={{ maxHeight: "800px" }}
      >
        {Object.keys(groupedDomains).length > 0 ? (
          Object.keys(groupedDomains)
            .sort()
            .map((letter) => (
              <div key={letter} className="relative">
                {/* Sticky Alphabet Header */}
                <div className="sticky top-0 z-10 bg-gray-50 px-3 py-1.5 text-sm font-semibold leading-6 text-gray-900 border-y border-gray-200">
                  <h3>{letter}</h3>
                </div>
                <ul className="divide-y divide-gray-100">
                  {groupedDomains[letter].map((domain, index) => (
                    <li
                      key={index}
                      className="flex justify-between gap-x-4 px-3 py-5"
                    >
                      <div className="text-sm font-semibold leading-6 text-gray-900 flex items-center">
                        {domain.domain}
                      </div>
                      <div>
                        {domain.available ? (
                          <button
                            type="button"
                            onClick={() => handleBuyNowClick(domain)}
                            className="inline-flex items-center rounded-md bg-indigo-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                          >
                            BUY NOW
                          </button>
                        ) : (
                          <div>UNAVAILABLE</div>
                        )}
                      </div>
                    </li>
                  ))}
                </ul>
              </div>
            ))
        ) : (
          <p className="py-4 px-6 text-center text-sm text-gray-500">
            No available domains found.
          </p>
        )}
      </div>
    </div>
  );
};

export default ResultsTable;
