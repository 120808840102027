// src/common/layouts/AppPageLayout.js
export default function AppPageLayout({ children }) {
  return (
    <div className="min-h-screen flex flex-col bg-gray-100">
      {/* <NavBar /> */}
      <div className="w-full flex justify-center p-4">
        <div className="w-full max-w-md bg-white p-8 rounded-lg shadow-lg">
          {children}
        </div>
      </div>
    </div>
  );
}
