export const popularTlds = [
  ".com",
  ".ai",
  ".info",
  ".io",
  ".net",
  ".org",
  ".app",
  ".biz",
  ".bio",
  ".cc",
  ".dev",
  ".gg",
  ".inc",
  ".is",
  ".me",
  ".so",
  ".us",
  ".wiki",
  ".xyz",
];
export const internationalTlds = [
  ".ac",
  ".ag",
  ".am",
  ".amsterdam",
  ".asia",
  ".at",
  ".au",
  ".be",
  ".biz",
  ".biz.pl",
  ".bz",
  ".ca",
  ".cc",
  ".ch",
  ".cl",
  ".cn",
  ".co",
  ".co.in",
  ".co.jp",
  ".co.kr",
  ".co.nz",
  ".co.uk",
  ".co.za",
  ".com.ag",
  ".com.au",
  ".com.br",
  ".com.bz",
  ".com.cn",
  ".com.co",
  ".com.es",
  ".com.ky",
  ".com.mx",
  ".com.pe",
  ".com.ph",
  ".com.pl",
  ".com.ru",
  ".com.tw",
  ".cz",
  ".de",
  ".dk",
  ".es",
  ".eu",
  ".firm.in",
  ".fm",
  ".fr",
  ".gen.in",
  ".gg",
  ".gs",
  ".id",
  ".idv.tw",
  ".in",
  ".ind.in",
  ".info",
  ".info.pl",
  ".io",
  ".it",
  ".jobs",
  ".jp",
  ".kr",
  ".ky",
  ".la",
  ".me",
  ".me.uk",
  ".mobi",
  ".ms",
  ".mx",
  ".name",
  ".ne.kr",
  ".net",
  ".net.ag",
  ".net.au",
  ".net.br",
  ".net.bz",
  ".net.cn",
  ".net.co",
  ".net.in",
  ".net.ky",
  ".net.nz",
  ".net.pe",
  ".net.ph",
  ".net.pl",
  ".net.ru",
  ".nl",
  ".no",
  ".nom.co",
  ".nom.es",
  ".nom.pe",
  ".org",
  ".org.ag",
  ".org.au",
  ".org.cn",
  ".org.es",
  ".org.in",
  ".org.ky",
  ".org.nz",
  ".org.pe",
  ".org.ph",
  ".org.pl",
  ".org.ru",
  ".org.uk",
  ".pe",
  ".ph",
  ".pl",
  ".pro",
  ".re.kr",
  ".se",
  ".sg",
  ".sh",
  ".tv",
  ".tw",
  ".uk",
  ".us",
  ".vc",
  ".ws",
  ".xxx",
  ".barcelona",
  ".bayern",
  ".berlin",
  ".boston",
  ".city",
  ".cymru",
  ".earth",
  ".irish",
  ".ist",
  ".istanbul",
  ".kiwi",
  ".land",
  ".lat",
  ".london",
  ".melbourne",
  ".miami",
  ".nagoya",
  ".nrw",
  ".nyc",
  ".okinawa",
  ".paris",
  ".quebec",
  ".ryukyu",
  ".stream",
  ".sydney",
  ".tokyo",
  ".town",
  ".vegas",
  ".wales",
  ".world",
  ".yokohama",
];
