import React from "react";
import { RouterProvider } from "react-router-dom";
import { AuthProvider } from "./common/contexts/AuthContext";
import { isInitialized } from "./firebase";
import "./index.css";
import router from "./Router";

function App() {
  return (
    <AuthProvider isInitialized={isInitialized}>
      <div className="center-content">
        <RouterProvider router={router} />
      </div>
    </AuthProvider>
  );
}

export default App;
