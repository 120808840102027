import { createBrowserRouter } from "react-router-dom";
import LogoutPage from "./common/pages/LogoutPage";
import Affiliate from "./pages/Affiliate";
import Home from "./pages/Home";

const router = createBrowserRouter([
  {
    path: "/",
    element: <Home />,
  },
  {
    path: "/logout",
    element: <LogoutPage />,
  },
  {
    path: "/affiliate",
    element: <Affiliate />,
  },
  {
    path: "/affiliate/:domainName",
    element: <Affiliate />,
  },
  {
    path: "*",
    element: <p>404 Error - Nothing here...</p>,
  },
]);

export default router;
